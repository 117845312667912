<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper
                    v-model="getEnforcementsErrors.step"
                    class="elevation-0"
                >
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () =>
                                        getEnforcementsErrors.stepOne
                                            .validation,
                                ]"
                                editable
                                step="1"
                                >{{
                                    $t('enforcements:basicInfo')
                                }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content class="pb-0 pt-4" step="1">
                                <v-row>
                                    <v-col cols="12" md="12" class="pt-0">
                                        <Separator
                                            :text="$t('enforcements:basicInfo')"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6"
                                        ><v-text-field
                                            v-model="getEnforcementsModal.title"
                                            :error-messages="
                                                getEnforcementsErrors.stepOne
                                                    .fields.title
                                            "
                                            :label="`${$t(
                                                'enforcements:title'
                                            )}*`"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-combobox
                                            v-model="
                                                getEnforcementsModal.contractor
                                            "
                                            :label="`${$t(
                                                'enforcements:contractor'
                                            )}*`"
                                            hint="Zacznij pisać..."
                                            @keyup="
                                                setContacts($event.target.value)
                                            "
                                            @input="assignID()"
                                            :hide-no-data="hideContacts"
                                            :items="getContactsInput.items"
                                            item-text="name"
                                            item-value="_id"
                                            return-object
                                            :error-messages="
                                                getEnforcementsErrors.stepOne.fields
                                                    .contractor
                                            "
                                        >
                                        </v-combobox>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-combobox
                                            :label="`${$t(
                                                'enforcements:representative'
                                            )}`"
                                            v-model="
                                                getEnforcementsModal.representative
                                            "
                                            @keyup="
                                                setRepresentatives(
                                                    $event.target.value
                                                )
                                            "
                                            :item-text="text"
                                            return-object
                                            append-outer-icon="mdi-account-plus"
                                            item-value="_id"
                                            :items="
                                                getRepresentativeInput.items
                                            "
                                            ><template
                                                slot="selection"
                                                slot-scope="data"
                                            >
                                                {{ data.item.name }}
                                                {{ data.item.lastname }}
                                            </template>
                                            <template
                                                slot="item"
                                                slot-scope="data"
                                            >
                                                {{ data.item.name }}
                                                {{ data.item.lastname }}
                                            </template>
                                            <template slot="append-outer">
                                                <v-menu
                                                    :close-on-content-click="
                                                        false
                                                    "
                                                    :close-on-click="false"
                                                    @input="
                                                        v =>
                                                            v ||
                                                            clearMiniModal()
                                                    "
                                                    max-width="300"
                                                    min-width="300"
                                                    origin="center"
                                                    v-model="showMenu"
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on: menu,
                                                        }"
                                                    >
                                                        <v-tooltip top>
                                                            <template
                                                                v-slot:activator="{
                                                                    on: tooltip,
                                                                }"
                                                            >
                                                                <v-btn
                                                                    small
                                                                    icon
                                                                    :disabled="
                                                                        getContactsInput
                                                                            .items
                                                                            .length >
                                                                        0
                                                                            ? false
                                                                            : true
                                                                    "
                                                                >
                                                                    <v-icon
                                                                        v-on="{
                                                                            ...menu,
                                                                            ...tooltip,
                                                                        }"
                                                                        @click="
                                                                            showMenu = true
                                                                        "
                                                                        >mdi-plus</v-icon
                                                                    >
                                                                </v-btn>
                                                            </template>
                                                            <span>{{
                                                                $t(
                                                                    'enforcements:addRepresentative'
                                                                )
                                                            }}</span>
                                                        </v-tooltip>
                                                    </template>
                                                    <v-card>
                                                        <v-container>
                                                            <v-row>
                                                                <v-col
                                                                    cols="12"
                                                                >
                                                                    <h3>
                                                                        {{
                                                                            `${$t(
                                                                                'enforcements:additionRepresentative'
                                                                            )}`
                                                                        }}
                                                                    </h3>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col
                                                                    cols="12"
                                                                >
                                                                    <v-text-field
                                                                        dense
                                                                        v-model="
                                                                            getRepresentativeModal.name
                                                                        "
                                                                        :label="`${$t(
                                                                            'enforcements:representativeName'
                                                                        )}`"
                                                                    >
                                                                    </v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col
                                                                    cols="12"
                                                                >
                                                                    <v-text-field
                                                                        dense
                                                                        v-model="
                                                                            getRepresentativeModal.lastname
                                                                        "
                                                                        :label="`${$t(
                                                                            'enforcements:representativeLastname'
                                                                        )}`"
                                                                    >
                                                                    </v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col
                                                                    cols="12"
                                                                >
                                                                    <v-text-field
                                                                        dense
                                                                        v-model="
                                                                            getRepresentativeModal.email
                                                                        "
                                                                        :label="`${$t(
                                                                            'enforcements:representativeEmail'
                                                                        )}`"
                                                                    >
                                                                    </v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col
                                                                    cols="12"
                                                                >
                                                                    <v-text-field
                                                                        dense
                                                                        v-model="
                                                                            getRepresentativeModal.phoneNumber
                                                                        "
                                                                        :label="`${$t(
                                                                            'enforcements:representativePhoneNumber'
                                                                        )}`"
                                                                    >
                                                                    </v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col
                                                                    md="12"
                                                                    class="py-0 my-0 d-flex justify-end"
                                                                >
                                                                    <v-btn
                                                                        class="buttons buttons--cancel mr-2"
                                                                        @click="
                                                                            clearMiniModal()
                                                                        "
                                                                        >{{
                                                                            $t(
                                                                                'cancel'
                                                                            )
                                                                        }}</v-btn
                                                                    >
                                                                    <v-btn
                                                                        class="buttons buttons--add"
                                                                        :disabled="
                                                                            disabled
                                                                        "
                                                                        @click="
                                                                            additionRepresentative()
                                                                        "
                                                                        >{{
                                                                            $t(
                                                                                'add'
                                                                            )
                                                                        }}</v-btn
                                                                    >
                                                                </v-col>
                                                            </v-row>
                                                        </v-container>
                                                    </v-card>
                                                </v-menu>
                                            </template>
                                        </v-combobox>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-textarea
                                            :label="`${$t(
                                                'enforcements:description'
                                            )}`"
                                            rows="1"
                                            auto-grow
                                            no-resize
                                            v-model="
                                                getEnforcementsModal.description
                                            "
                                        >
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    data() {
        return {
            hideContacts: true,
            disabled: false,
            showMenu: false,
            step: 1,
            editable: true,
        }
    },
    methods: {
        ...mapActions([
            'fetchContactsInputs',
            'createRepresentative',
            'fetchRepresentativesInput',
        ]),
        ...mapMutations(['setContactsInput']),
        text: item => item.name + item.lastname,
        setContacts(search) {
            if (search.length >= 4) {
                this.setContactsInput({ search })
                this.fetchContactsInputs()
                this.hideContacts = false
            } else {
                this.getContactsInput.items = []
                this.hideContacts = true
            }
        },
        assignID() {
            if (
                this.getEnforcementsModal.contractor &&
                this.getEnforcementsModal.contractor._id
            ) {
                this.getEnforcementsModal.representative = null
                this.fetchRepresentativesInput({
                    id: this.getEnforcementsModal.contractor._id,
                })
            }
        },
        async additionRepresentative() {
            this.disabled = true
            let success = await this.createRepresentative({
                id: this.getEnforcementsModal.contractor._id,
            })
            if (success) {
                this.clearMiniModal()
                this.assignID()
            }

            this.disabled = false
        },
        clearMiniModal() {
            this.showMenu = false
        },
    },
    computed: {
        ...mapGetters([
            'getEnforcementsModal',
            'getEnforcementsErrors',
            'getRegistryDictionaries',
            'getContactsInput',
            'getRepresentativeInput',
            'getRepresentativeModal',
        ]),
    },
}
</script>
