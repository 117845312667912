<template>
    <v-container class="p-relative">
        <v-row>
            <v-col cols="12" md="12" class="py-0">
                <RegistryHeader
                    :title="getEnforcementsRegistry.name"
                    :avatarData="getEnforcementsRegistry.currentMenager"
                    @clearSearch="clearTable"
                />

                <Table
                  :title="getEnforcementsRegistry.name"
                    :items="getEnforcementsTable.items"
                    :headers="headers"
                    :length="getEnforcementsTable.pages"
                    :page="getEnforcementsTable.page"
                    @search="setSearch"
                    @clear-table="clearTable"
                    @search-fields="setSearchFields"
                    @change-page="setPage"
                    @sort="setSort"
                    :defaultFields="getEnforcementsSearchFields.searchFields"
                    :loading="getEnforcementsTable.loading"
                >
                    <Columns
                        slot="columns"
                        :items="getEnforcementsTable.items"
                    />
                </Table>
                <Modal
                    :title="$t('enforcements:addEnfrorcement')"
                    :open="open"
                    :height="'520'"
                    v-on:close="closeModal"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        :action="'create'"
                        slot="buttons"
                        @closeModal="open = false"
                    />
                </Modal>
                <div class="d-flex justify-end buttons--action">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                data-cy="addEntry"
                                fab
                                dark
                                v-on="on"
                                small
                                v-if="add()"
                                @click="addEnforcement()"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('enforcements:addEnfrorcement') }}</span>
                    </v-tooltip>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import store from './../../../store/index'
import Columns from '../../../components/Registries/Enforcements/Table/Columns.vue'
import AEContent from './../../../components/Registries/Enforcements/Modal/Content'
import Buttons from './../../../components/Registries/Enforcements/Modal/Buttons'
export default {
    data() {
        return {
            open: false,
            headers: [
                {
                    text: this.$t('enforcements:index'),
                    value: 'index',
                    width: '10%',
                    showSearch: true,
                },
                {
                    text: this.$t('enforcements:title'),
                    value: 'title',
                    width: '25%',
                    showSearch: true,
                },
                {
                    text: this.$t('enforcements:contractor'),
                    value: 'contractor.name',
                    width: 'auto',
                    showSearch: true,
                },
                {
                    text: this.$t('enforcements:status'),
                    value: 'status.name',
                    width: '15%',
                    showSearch: true,
                },
                {
                    text: '',
                    value: 'arrow',
                    align: 'right',
                    width: '6%',
                    sortable: false,
                },
            ],
        }
    },
    components: {
        Columns,
        AEContent,
        Buttons,
    },
    computed: {
        ...mapGetters([
            'getEnforcementsRegistry',
            'getEnforcementsTable',
            'getEnforcementsSearchFields',
            'getSearch',
        ]),
    },
    methods: {
        ...mapActions(['fetchEnforcementsTable', 'fetchUsers']),
        ...mapMutations([
            'clearEnforcementsModal',
            'clearEnforcementsErrors',
            'setEnforcementsTable',
            'clearEnforcementsTable',
            'setEnforcementsSearch',
        ]),
        add() {
            return this.checkPermissions('CREATE')
        },
        closeModal() {
            this.clearEnforcementsErrors()
            this.open = false
        },
        async addEnforcement() {
            this.open = true
            this.clearEnforcementsErrors()
            this.clearEnforcementsModal()
        },
        setSearchFields(searchFields) {
            this.setEnforcementsSearch(searchFields)
        },
        setSearch(search) {
            this.setEnforcementsTable({ search, page: 1 })
            this.fetchEnforcementsTable()
        },
        setPage(page) {
            this.setEnforcementsTable({ page })
            this.fetchEnforcementsTable()
        },
        setSort(sort) {
            this.setEnforcementsTable({ sort })
            this.fetchEnforcementsTable()
        },
        clearTable() {
            this.getSearch.text = ''
            this.clearEnforcementsTable()
            this.fetchEnforcementsTable()
        },
    },
    beforeRouteEnter(to, from, next) {
        if (from.matched[1] && from.matched[1].path !== '/enforcements/:id')
            store.commit('clearEnforcementsTable')
        store.dispatch('fetchEnforcementsTable').then(() => next())
    },
}
</script>
